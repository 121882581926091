import React from 'react';
import './App.css';
import { Stack, Button, Badge } from 'react-bootstrap';
import { useState } from 'react';
import { InfluxDB } from '@influxdata/influxdb-client';
import GraphComponent from './Graph';
const url = 'https://eu-central-1-1.aws.cloud2.influxdata.com';
const token = 'Ya4j2OwRi_mOzWVA_89lvjWMPozg5vpwIYmRTyJ4P379ARzYEgPZrOCwSVWJcvq5ZRv6Gw7veRFrolniXtGh9A==';
const org = 'a70f8fc35918e642';
const bucket = 'current_sensor_readings';
type WashingMachineStatus = {
  hoursRemaining: number;
  minutesRemaining: number;
  secondsRemaining: number;
  switchOn: boolean;
  id: number;
};

const queryApi = new InfluxDB({ url, token }).getQueryApi(org);
export const queryInfluxDB = async (fluxQuery: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const rows: any[] = [];

    queryApi.queryRows(fluxQuery, {
      next(row, tableMeta) {
        const o = tableMeta.toObject(row);
        rows.push(o);
      },
      error(error) {
        console.error(error);
        reject(error);
      },
      complete() {
        resolve(rows);
      },
    });
  });
};

function App() {
  const [washingMachineStatus, setWashingMachineStatus] = useState<WashingMachineStatus[]>([]);
  const [graphData, setGraphData] = useState<any[]>([]);
  const [showGraph, setShowGraph] = useState(false);
  const handleTopUp = async (id: number) => {
    try {
      await fetch(`http://ec2-3-86-101-136.compute-1.amazonaws.com/washing-machine/super-admin/top-up`, {
        method: 'POST',
        body: JSON.stringify({ washingMachineId: id, minutes: 10 }),
      });
      await checkAlive();
    } catch (error) {
      console.error('Error topping up washing machine:', error);
    }
  };
  const checkAlive = async () => {
    try {
      const response = await fetch('http://ec2-3-86-101-136.compute-1.amazonaws.com/super-admin/all-machine-states');
      const data = await response.json();
      
      const activeStates = data.map((machine: any) => ({
        hoursRemaining: machine.hours,
        minutesRemaining: machine.minutes,
        secondsRemaining: machine.seconds,
        switchOn: true,
        id: machine.machineId
      }));

      setWashingMachineStatus(activeStates);
    } catch (error) {
      console.error('Error fetching machine states:', error);
    }
  };

  const handleTurnOff = async (id: number) => {
    // await fetch(`http://ec2-3-86-101-136.compute-1.amazonaws.com/washing-machine/super-admin/turn-off`, {
    //   method: 'POST',
    //   body: JSON.stringify({ washingMachineId: id }),
    // });
    // await checkAlive();
  };

  const showAndRefreshGraph = async (id: number) => {

    const influxQuery = `from(bucket: "current_sensor_readings")
  |> range(start: -2h)
  |> filter(fn: (r) => r._measurement == "power_readings")
  |> filter(fn: (r) => r._field == "power" and exists r._value)
  |> filter(fn: (r) => r.washingMachineId == "${id}")
`;
    console.log(influxQuery);
    const data = await queryInfluxDB(influxQuery);


    const transformedData = data.map((item: any) => {
      return {
        time: item._time,
        power: item._value,
      };
    });
    setGraphData(transformedData);
    setShowGraph(true);
    console.log(transformedData);
  };
  return (
    <div className="App">
      <Stack direction="horizontal" gap={2}>
        <Button as="a" variant="primary" onClick={checkAlive}>
          Check who is alive now
        </Button>
      </Stack>
      {washingMachineStatus
        .filter((status) => status.switchOn)
        .map((status) => (
          <div key={status.id}>
            {status.id} On {status.hoursRemaining}h {status.minutesRemaining}m {status.secondsRemaining}
            <Button variant="success" size="lg" className="ms-2" onClick={() => handleTopUp(status.id)}>
              +10
            </Button>
            <Button variant="danger" size="lg" className="ms-2" onClick={() => handleTurnOff(status.id)}>
              Off
            </Button>
            <Button variant="warning" size="lg" className="ms-2" onClick={() => showAndRefreshGraph(status.id)}>
              Graph Refresh 
            </Button>
          </div>
        ))}
        {showGraph && <GraphComponent data={graphData}></GraphComponent>}
    </div>
  );
}

export default App;
