import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface GraphComponentProps {
  data: { time: string; power: number }[];
}

function GraphComponent({ data }: GraphComponentProps) {
  return (
    <div style={{backgroundColor: 'black'}}>
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="power" stroke="#00bfff" dot={false} activeDot={false} />
      </LineChart>
    </ResponsiveContainer>
    </div>
  );
}

export default GraphComponent;
